import {onDomReady} from "../components/dynamic/observer";
/**
 * https://learn.javascript.ru/regexp-unicode
 * @type {RegExp}
 */
onDomReady(() => {
    const articles = document.querySelectorAll('[data-article]');
    articles.forEach((article) => {
        if (article) {
            const articleText = article.querySelector('[data-article-text]');
            if (articleText) {
                const regex = /( |<([^>]+)>)/ig;
                const cleanTags = articleText.textContent.replace(regex, "");
                const cleanSpaces = cleanTags.replace(/\s{2,}/g, '');
                const textLength = cleanSpaces.length;
                const readingTime = Math.ceil(textLength / 1500);
                const timeWrapper = article.querySelector('[data-article-reading-time]');
                timeWrapper.innerHTML = readingTime.toString();
            }
        }
    })

})