// const foxBanner = document.querySelector('[data-fox]');
// if (foxBanner) {
//     const submitButton = foxBanner.querySelector('[data-cookies-sumbit]');
//
//     const getCookie = (name) => {
//         let matches = document.cookie.match(new RegExp(
//             "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
//         ));
//         return matches ? decodeURIComponent(matches[1]) : undefined;
//     }
//
//     const acceptCookies = getCookie('cookies');
//
//     if (acceptCookies) {
//         submitButton.style.display = 'none';
//     }
//
//     if (submitButton) {
//         submitButton.addEventListener('click', () => {
//             let expire = new Date();
//             expire = new Date(expire.getTime()+31536000000);
//             document.cookie = "cookies=accept; path=/; expires=" + expire.toUTCString();
//             foxBanner.style.display = 'none';
//         })
//     }
// }
//
