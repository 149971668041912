import { onDomReady } from "../components/dynamic/observer";

onDomReady(() => {
    const partnerPage = document.querySelector('[data-partner-page]');
    if (partnerPage) {
        const totalSumEl = partnerPage.querySelector('[data-total-sum]');
        const totalDayEl = partnerPage.querySelector('[data-total-month]');
        const creditSumEl = partnerPage.querySelector('[data-credit-sum]');

        const sumMinusEl = partnerPage.querySelector('[data-sum-minus]');
        const sumPlusEl = partnerPage.querySelector('[data-sum-plus]');
        const dayMinusEl = partnerPage.querySelector('[data-month-minus]');
        const dayPlusEl = partnerPage.querySelector('[data-month-plus]');

        const commission = partnerPage.querySelector('[data-commission]');
        const percent = partnerPage.querySelector('[data-percent]');
        const rate = partnerPage.querySelector('[data-rate]').textContent;




        const period = partnerPage.querySelector('[data-period]').textContent;

        sumMinusEl.addEventListener('click', () => {
            let currentSum = parseInt(creditSumEl.textContent);
            if (!isNaN(currentSum) && currentSum > 0) {
                creditSumEl.textContent = `${(currentSum - 500).toFixed(0)} zł`;
            }
            calculateTotal(period);
        });

        sumPlusEl.addEventListener('click', () => {
            let currentSum = parseInt(creditSumEl.textContent);
            if (!isNaN(currentSum)) {
                creditSumEl.textContent = `${(currentSum + 500).toFixed(0)} zł`;
            }
            calculateTotal(period);
        });

        dayMinusEl.addEventListener('click', () => {
            let currentDays = parseInt(totalDayEl.textContent);
            if (!isNaN(currentDays) && currentDays > 1) {
                totalDayEl.textContent = (currentDays - 1).toString();
            }
            calculateTotal(period);
        });

        dayPlusEl.addEventListener('click', () => {
            let currentDays = parseInt(totalDayEl.textContent);
            if (!isNaN(currentDays)) {
                totalDayEl.textContent = (currentDays + 1).toString();
            }
            calculateTotal(period);
        });

        function calculateTotal(period) {
            if (period === 'days') {
                const loanAmount = parseInt(creditSumEl.textContent);
                const commissionAmount = parseInt(commission.textContent);

                const days = parseInt(totalDayEl.textContent);
                const dailyInterestRate = parseInt(rate) / 365 / 100;
                const interestAmount = loanAmount * dailyInterestRate * days;
                const totalRepaymentAmount = loanAmount + interestAmount + commissionAmount;

                totalSumEl.textContent = `${totalRepaymentAmount.toFixed(2)} zł`;
                percent.textContent = interestAmount.toFixed(2);
                commission.textContent = commissionAmount.toFixed(2);
            }
            if (period === 'months') {
                const loanAmount = parseInt(creditSumEl.textContent);
                const days = parseInt(totalDayEl.textContent);
                const monthInterestRate = parseInt(rate) / 12 / 100;
                const interestAmount = loanAmount * monthInterestRate * days;
                const totalRepaymentAmount = loanAmount + interestAmount;

                totalSumEl.textContent = `${totalRepaymentAmount.toFixed(2)} zł`;
                percent.textContent = interestAmount.toFixed(2);
            }
        }
    }

});
