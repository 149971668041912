class Header {
    constructor() {
        this.option = {
            header: '[data-header]',
            menuButton: '[data-expand-button]',
            burgerAttribute: '[data-header-hamburger]',
            burger_opened: 'header__hamburger_active',
            header_opened: 'header_opened',
            droppedMenu: '[data-menu]',
            droppedSecondMenu: '[data-second-menu]',
            activeClass: '_active'
        }
        this.elements = {
            menuButtons: document.querySelectorAll(this.option.menuButton),
            burger: document.querySelector(this.option.burgerAttribute),
            menu: document.querySelector(this.option.droppedMenu),
            header: document.querySelector(this.option.header),
            subMenu: document.querySelector(this.option.droppedSecondMenu),
        }

        this.init();
    }

    init() {
        if (this.elements.menuButtons) {
            const elements = this.elements.menuButtons;
            elements.forEach((element) => {
                const droppedMenu = element.querySelector('[data-expand-menu]');
                droppedMenu.addEventListener('mouseleave', () => {
                    setTimeout(() => {
                        droppedMenu.classList.remove(this.option.activeClass);
                        element.classList.remove(this.option.activeClass);
                    }, 200);
                });
                element.addEventListener('click', (e) => {
                    if (!droppedMenu.classList.contains(this.option.activeClass)) {
                        elements.forEach((el) => {
                            const menu = el.querySelector('[data-expand-menu]');
                            menu.classList.remove(this.option.activeClass);
                            el.classList.remove(this.option.activeClass);
                        });

                        droppedMenu.classList.add(this.option.activeClass);
                        element.classList.add(this.option.activeClass);
                    } else {
                        droppedMenu.classList.remove(this.option.activeClass);
                        element.classList.remove(this.option.activeClass);
                    }
                });
            });
        }

        if (this.elements.burger) {
            this.elements.burger.addEventListener('click', () => {
                if (!this.elements.header.classList.contains(this.option.header_opened)) {
                    if (this.elements.menu) {
                        this.elements.menu.classList.add('_active');
                    } else {
                        this.elements.menu.classList.remove('_active');
                    }
                    document.body.style.overflow = 'hidden';
                    this.elements.header.classList.add(this.option.header_opened);
                    this.elements.burger.classList.add(this.option.burger_opened);
                } else {
                    document.body.style.overflow = 'auto';
                    this.elements.header.classList.remove(this.option.header_opened);
                    this.elements.burger.classList.remove(this.option.burger_opened);
                    this.elements.menuButtons.forEach((button) => {
                        const droppedMenu = button.querySelector('[data-expand-menu]');
                        droppedMenu.classList.remove('_active');
                    })
                    this.elements.menu.classList.remove('_active');
                }
            })
        }
    }
}

document.addEventListener('DOMContentLoaded', function () {
    new Header();
});