/**
 * Слушать событие готовности DOM-дерева после загрузки или изменений
 *
 * @param callback
 * @param mutated
 */
function onDomReady(callback, mutated = true) {
  if (document.readyState === "complete"
    || document.readyState === "loaded"
    || document.readyState === "interactive") {
    callback();
  } else {
    document.addEventListener('DOMContentLoaded', callback);
  }
  if (mutated) {
    document.addEventListener('DOMContentMutated', callback);
  }
}

export {onDomReady};